const Property = ({prop, text}) => {
    if (!prop) return null;

    return (
        <tr>
            <th>{text}</th>
            <td>{prop}</td>
        </tr>
    ); 
}

const Farg = ({
    Farger
}) => {
    if (!Farger) {
        return null;
    }

    if (Farger.length === 0) {
        return null;
    }

    return (
        <section className="product-sheet-box">
            <div className="product-sheet-box__inner">
                <h2 className="product-sheet-box__heading">Färg</h2>
                {Farger.map((farg, index) => {
                    return (
                        <table className="product-sheet__table product-sheet__table--align-right">
                            <tbody>
                                <Property prop={farg.Fargbeskrivning} text="Färg:" />
                                <Property prop={farg.Fargkod} text="Färgkod:" />
                                <Property prop={farg.Fargsystem} text="Färgsystem:" />
                            </tbody>
                        </table>
                    );
                })}
                
            </div>
        </section>
    );
};

export default Farg;