const Property = ({prop, text}) => {
    if (!prop) return null;

    return (
        <tr>
            <th>{text}</th>
            <td>{prop}</td>
        </tr>
    ); 
}

const Characteristics = ({
    ArtikelegenskapText,
    Produktegenskaper
}) => {

    if (!(ArtikelegenskapText || (Produktegenskaper && Produktegenskaper.length > 0))) {
        return null;
    }

    return (
        <section className="product-sheet-box">
            <div className="product-sheet-box__inner">
                <h2 className="product-sheet-box__heading">Artikelegenskaper</h2>
                <table className="product-sheet__table product-sheet__table--align-right">
                    <tbody>
                        <Property prop={ArtikelegenskapText} text="Produktfunktion:" />
                        {Produktegenskaper.map((egenskap, index) => {
                            return (
                                <Property key={index} prop={egenskap.Värde} text={`${egenskap.ProduktEgenskapTyp}:`} />
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>
    );
};

export default Characteristics;