import React, { Component } from 'react';
import { withRouter } from '../../hoc/withRouter';
import queryString from 'query-string';
import Product from '../../hoc/product';
import Tillagningsinformation from './tillagningsinformation';
import Forvaring from './forvaring';
import Naringsinformation from './naringsinformation';
import Allergener from './allergener';
import Ursprung from './ursprung';
import Ingredienser from './ingredienser';
import Produktkod from './produktkod';
import Markning from './markning';
import Pastaende from './pastaende';
import Image from './image';
import Toolbar from './toolbar';
import Footer from './footer'; 
import GeneralInfo from './generalinfo';
import Recipes from './recipes';
import Matt from './matt';
import Material from './material';
import Farg from './farg';
import Characteristics from './characteristics';
import Instruktion from './instruktion';
import classNames from 'classnames';
import './styles.scss';


import Bas from './bas';

const Marknadsbudskap = (product) => {
 
    if (product.Marknadsbudskap && product.Marknadsbudskap.length > 0) {
        return (
            <section className="product-sheet-box">
                <div className="product-sheet-box__inner">
                    <h2 className="product-sheet-box__heading">Marknadsbudskap</h2>
                    {product.Marknadsbudskap.map((budskap, i) => <p key={`marknadsbudskap${i}`}>{budskap.MarknadsbudskapText}</p>)}
                </div>
            </section>
        );
    }

    if (product.KortMarknadsbudskap && product.KortMarknadsbudskap.length > 0) {
        return (
            <section className="product-sheet-box">
                <div className="product-sheet-box__inner">
                    <h2 className="product-sheet-box__heading">Marknadsbudskap</h2>
                    {product.KortMarknadsbudskap.map((budskap, i) => <p key={`kortmarknadsbudskap${i}`}>{budskap.KortMarknadsbudskapText}</p>)}
                </div>
            </section>
        );
    } 

    return null;
};

class Productsheet extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        
        const { product, root, location, ...flattendProductHierarchy } = this.props;
        const qs = queryString.parse(location.search);
        
        const heading = product.Produktnamn && product.Produktnamn !== '' ?  product.Produktnamn : product.Artikelkategori;
        const subHeadeing = product.Produktnamn && product.Produktnamn !== '' ?  product.Artikelkategori : ''; 
        const productType = product.ArticleType;
        
        return (
            <div className={classNames({
                'product-sheet': true,
                'product-sheet--has-logo': product.Uppgiftslamnare && product.Uppgiftslamnare.LogoLiten
            })}>
                {product.Uppgiftslamnare && product.Uppgiftslamnare.LogoLiten &&
                    <header className="product-sheet__header">
                        <img src={product.Uppgiftslamnare.LogoLiten} alt="" />
                    </header>
                }

                <div className="product-sheet__content">
                    <div className="product-sheet__generic-container">
                        <div className="product-sheet__heading-conatiner">
                            {subHeadeing &&
                                <h2 className="product-sheet__subheading">{subHeadeing}</h2>
                            }
                            <h1 className="product-sheet__heading">{heading}</h1>
                        </div>
                        <Image {...product} />
                        <GeneralInfo {...product} uppgiftslamnare={product.Uppgiftslamnare} />
                    </div>
                    
                    <div className="product-sheet-boxes">
                        <div className="product-sheet-boxes__col">
                            <Ingredienser {...product} />
                            <Marknadsbudskap {...product} />
                            <Produktkod {...product} />
                            <Markning {...product} />
                            <Pastaende {...product} />
                            <Tillagningsinformation {...product} />
                            <Forvaring {...product} />
                            {productType === 'NOON_FOOD' &&
                                <>
                                    <Matt {...product} />
                                </>
                            }
                        </div>
                        <div className="product-sheet-boxes__col">
                            <Ursprung {...product} />
                            {productType === 'NOON_FOOD' &&
                                <>
                                    <Characteristics {...product} />
                                    <Instruktion heading="Användningsinstruktioner" instruction={product.Anvandningsinstruktioner} />
                                    <Instruktion heading="Återvinningsinstruktioner" instruction={product.T4306_Atervinningsinstruktioner} />
                                    <Farg {...product} />
                                </>
                            }
                            <Naringsinformation {...product} />
                            <Allergener {...product} />
                        </div>
                        {productType === 'NOON_FOOD' &&
                            <Material {...product} />
                        }
                        <Recipes {...product} />
                    </div>
                    
                    {!qs.short &&
                        <>
                            {flattendProductHierarchy.base.map((base, i) => {
                                return <Bas key={`bas${i}`} {...base} heading="Basnivå" />
                            })}

                            {flattendProductHierarchy.middle.map((middle, i) => {
                                return <Bas key={`middle${i}`} {...middle} heading="Mellannivå" />
                            })}

                            {flattendProductHierarchy.top.map((top, i) => {
                                return <Bas key={`top${i}`} {...top} heading="Toppnivå" />
                            })}
                        </>
                    }
                    
                    <Footer {...product} />
                </div>
                <Toolbar {...product} pdfUrl={`/productsheet/${product.GTIN}/pdf`} />
            </div>
        );
    }
};

export default withRouter(Product(Productsheet));