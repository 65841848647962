const Instruktion = ({
    heading,
    instruction
}) => {
    if (!instruction) {
        return null;
    }

    return (
        <section className="product-sheet-box">
            <div className="product-sheet-box__inner">
                <h2 className="product-sheet-box__heading">{heading}</h2>
                <p>{instruction}</p>
            </div>
        </section>
    );
};

export default Instruktion;