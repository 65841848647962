import './complex-table.scss';
const Property = ({prop, text}) => {
    if (!prop) return null;

    return (
        <tr>
            <th>{text}</th>
            <td>{prop}</td>
        </tr>
    ); 
}

const Material = ({ 
    Material 
}) => {    
    if (!Material || Material?.length === 0) {
        return null;
    }

    return (
        <section className="product-sheet-box product-sheet-box--wide product-sheet-box--material">
            {Material.map((mat, index) => {
                return (
                    <div key={index} className="product-sheet-box__inner">
                        <h2 className="product-sheet-box__heading">Material</h2>
                        <div className="complex-table-container__columns">
                            <div>
                                <table className="product-sheet__table product-sheet__table--align-right">
                                    <tbody>
                                        {mat.CanMaterialsBeSeparatedManually &&
                                            <Property prop={mat.CanMaterialsBeSeparatedManually === true ? 'Ja' : 'Nej'} text="Kan materialen lätt separeras:" />
                                        }
                                        {mat.IsDesignationIntendedForSingleUse &&
                                            <Property prop={mat.IsDesignationIntendedForSingleUse === true ? 'Ja' : 'Nej'} text="Är avsedd för engångsbruk:" />
                                        }
                                        <Property prop={mat.NumberOfDesignationUnits} text="Antal ingående delar per material:" />
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <table className="product-sheet__table product-sheet__table--align-right">
                                    <tbody>
                                        <Property prop={mat.MaterialStatement} text="Materialbeskrivning:" />
                                        <Property prop={mat.TradeItemMaterialDesignationDescription} text="Del av artikel:" />
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="complex-table-container">
                    
                            {mat.MaterialComposition && mat.MaterialComposition.length > 0 &&
                                <>
                                    <h3>Materialsammansättning</h3>
                                    <table className="complex-table">
                                        <thead>
                                            <tr>
                                                <th>Material</th>
                                                <th>Materialinnehåll i procent</th>
                                                <th>Denier</th>
                                                <th>Materialets råvara</th>
                                                <th>Materialets råvara i procent</th>
                                                <th>Materialets vikt</th>
                                                <th>Materialets trådtäthet</th>
                                                <th>Materialinnehåll</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {mat.MaterialComposition.map((comp, y) => {
                                                return (
                                                    <tr key={`comp${y}`}>
                                                        <td>{comp.MaterialCode && comp.MaterialCode.text}</td>
                                                        <td>{comp.MaterialPercentage && comp.MaterialPercentage}</td>
                                                        <td>{comp.ThreadDenier && comp.ThreadDenier}</td>
                                                        <td>{comp.RawMaterialCode && comp.RawMaterialCode.text}</td>
                                                        <td>{comp.RawMaterialContentPercentage && comp.RawMaterialContentPercentage}</td>
                                                        <td></td>
                                                        <td>{comp.MaterialThreadCount && comp.MaterialThreadCount}</td>
                                                        <td>{comp.MaterialContent && comp.MaterialContent}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </>
                            }
                        </div>
                    </div>
                );
            })}
        </section>
    );
};

export default Material;