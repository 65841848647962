import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import { withRouter } from './hoc/withRouter';
import './css/App.scss';
import './framework/helpers.scss';
import Header from './components/header';
import routes from './routes'
import Error from './pages/error';
class App extends Component {
    constructor(props) {
      super(props);

      let initialData;
      if (props.staticContext) {
          initialData = props.staticContext.initialData;
      } else {
          initialData = window.__initialData__;
          delete window.__initialData__;
      }
  
      this.state = {
        initialData: initialData
      };
    }

    render() {
      if (this.state.initialData && this.state.initialData.error) {
        return <Error status={this.state.initialData.status} />;
      }

      const { newsroom } = this.state.initialData;

      return (
        <>
          <Routes>
            {routes.map((route, i) => {
              return (
                <Route 
                  key={i} 
                  path={route.path} 
                  element={<>
                    <Header {...this.props} hiddenHeader={route.hiddenHeader || newsroom} {...this.state} />
                    <div className="app-content">
                      <div>
                        <route.component  {...this.props} {...this.state} />
                      </div>
                  </div>
                  </>}  />
              )
            })}
          </Routes>
        </>
      );
    }
};

export default withRouter(App);