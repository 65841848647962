const Property = ({prop, text}) => {
    if (!prop) return null;

    return (
        <tr>
            <th>{text}</th>
            <td>{prop}</td>
        </tr>
    ); 
}

const Matt = ({
    Bredd_Formatted,
    Hojd_Formatted,
    Djup_Formatted,
    Bruttovikt_Formatted,
    T4330_Nettovikt,
    Storleksmått
}) => {
    return (
        <section className="product-sheet-box">
            <div className="product-sheet-box__inner">
                <h2 className="product-sheet-box__heading">Mått & vikt</h2>
                <table className="product-sheet__table product-sheet__table--align-right">
                    <tbody>
                        <Property prop={`${Bredd_Formatted} mm`} text="Artikelns bredd:" />
                        <Property prop={`${Hojd_Formatted} mm`} text="Artikelns höjd:" />
                        <Property prop={`${Djup_Formatted} mm`} text="Artikelns djup:" />
                        <Property prop={`${Bruttovikt_Formatted} gram`} text="Artikelns bruttovikt:" />
                        <Property prop={`${T4330_Nettovikt.T4330_Värde_Formatted} gram`} text="Artikelns nettoviktvikt:" />
                        {Storleksmått && Storleksmått.map(item => {
                            return (
                                <Property prop={`${item.Värde_Formatted} ${item.Enhet && item.Enhet.toLowerCase()}`} text={`${item.Typ}:`} />
                            );
                        })}
                    </tbody>
                </table>
                {/*
                <div className="complex-table-container">
                    {Storleksmått && Storleksmått.length > 0 &&
                        <>
                            <h3>Storleksmått</h3>
                            <table className="complex-table">
                                <thead>
                                    <tr>
                                        <th>Typ av storleksmått</th>
                                        <th>Storleksmått</th>                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    {Storleksmått.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{item.Typ}</td>
                                                <td>{item.Värde_Formatted} {item.Enhet && item.Enhet.toLowerCase()}</td>
                                            </tr>
                                        );
                                    })}
                                    
                                </tbody>
                            </table>
                        </>
                    }
                </div>
                */}
            </div>
        </section>
    );
};

export default Matt;